<template>
  <div class="container">
    <app-bar
      title="Keranjang"
      previousLink="/" />

    <Loading v-if="loading.cart" />

    <empty
      v-else-if="cart.arr_paket.length < 1"
      message="Anda belum menambahkan item apa pun"/>

    <div v-else>
      <div class="row">
        <div class="col">
          <div class="mb-2">Lokasi Klinik</div>
          <router-link
            v-if="selectedBranch"
            :to="{
              name: 'SelectBranch',
              query: {
                redirectTo: $route.path
              }
            }"
            class="address-box bg-neutral-100 mb-3 d-flex align-items-center gap-2">
            <div
              class="icon-wrapper bg-neutral-300 d-flex align-items-center justify-content-center rounded-circle"
            >
              <img
                src="@/assets/icon/location.png"
                alt=""
              />
            </div>
            <div class="address-box-info d-flex flex-column">
              <strong class="address-box-info-name fw-700 color-neutral-900">{{ selectedBranch.nama }}</strong>
              <small class="address-box-info-address fw-400 color-neutral-600">{{ selectedBranch.alamat }}</small>
            </div>
          </router-link>
        </div>
      </div>

      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-between mb-2">
          <span class="">Pesanan</span>
          <router-link
            class="btn btn-add bg-secondary-100 rounded-pill py-0"
            :to="{ name: 'Home' }">
            <span class="btn-title fw-700 fs-12 lh-12 color-secondary-500 py-1">Tambah Item</span>
          </router-link>
        </div>
        <div class="col-12">
          <!-- <div class="recomended-list d-flex flex-column border">
            <div class="top-recomended d-flex align-items-center justify-content-between bg-neutral-100 rounded-3 p-2">
              <div>
                <span class="top-recomended-label fw-400 color-neutral-700">Rekomendasi Pemeriksaan</span>
                <p class="top-recomended-name fw-700 color-neutral-900 mb-0">dr. Niko Siahaan</p>
              </div>
              <input
                type="checkbox"
                class="form-check-input"
              />
            </div>
            <div class="recomended-item">
              <span class="recomended-item-name fw-600 color-neutral-700">Agregasi Trombosit (TAT)</span>
              <p class="recomended-item-price fw-400 color-secondary-500 mb-0">Rp. 99.000</p>
            </div>
            <div class="recomended-item">
              <span class="recomended-item-name fw-600 color-neutral-700">Analisa Hb ELP</span>
              <p class="recomended-item-price fw-400 color-secondary-500 mb-0">Rp. 99.000</p>
            </div>
            <div class="recomended-item">
              <span class="recomended-item-name fw-600 color-neutral-700">Alkali Fosfatase (ALP)</span>
              <p class="recomended-item-price fw-400 color-secondary-500 mb-0">Rp. 99.000</p>
            </div>
          </div> -->
          <div v-if="cart.arr_paket.length" class="list-service mb-5 mb-md-0">
            <div
              v-for="(item, i) in cart.arr_paket"
              :key="i"
              class="service d-flex justify-content-between align-items-center">
              <div class="service-group d-flex align-items-center gap-2">
                <div class="service-icon">
                  <img
                    src="@/assets/dummy/swab.png"
                    :alt="'Ikon' + item.nama"
                  />
                </div>
                <div class="service-info">
                  <span class="service-title fw-700 color-neutral-900">{{ item.nama }}</span>
                  <p class="service-price fs-400 color-secondary-500 mb-0">Rp. {{ parseFloat(item.harga).toLocaleString('id-ID') }}</p>
                </div>
              </div>

              <button
                class="btn"
                @click="removeFromCart(item)">
                <img
                  src="@/assets/icon/trash.png"
                  alt="ikon hapus"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-bar p-3">
      <router-link
        :to="cart.arr_paket.length !== 0 ? { name: 'UseVoucher', query: {from: 'keranjang'} } : ''"
        class="voucher bg-neutral-100 d-flex align-items-center justify-content-between mb-3"
      >
        <div class="d-flex align-items-center gap-2">
          <div class="voucher-icon d-flex align-items-center justify-content-center">
            <img
              src="@/assets/icon/voucher.png"
              alt=""
            >
          </div>
          <span class="voucher-label fw-400 color-neutral-900">Voucher Parahita</span>
        </div>
        <div class="d-flex align-items-center gap-2">
          <span class="voucher-use fw-400 color-secondary-500">Gunakan/masukkan kode</span>
          <div class="voucher-icon d-flex align-items-center justify-content-center">
            <img
              src="@/assets/icon/arrow-right-outline.png"
              alt=""
            >
          </div>
        </div>
      </router-link>
      <div class="pay d-flex align-items-center justify-content-between mb-md-3">
        <div class="pay-info d-flex flex-column">
          <span class="pay-label fw-400 color-neutral-600 mb-1">Total Pembayaran</span>
          <span class="pay-total fw-700 color-neutral-900">Rp. {{ total.toLocaleString('id-ID') }}</span>
        </div>
        <router-link
          :to="{ name: 'Checkout' }"
          class="btn btn-orange btn-pay ms-1 fw-600 fs-14 w-100 py-2 text-white
            d-flex justify-content-center align-items-center gap-1"
          :class="{
            disabled: cart.arr_paket.length < 1
          }"
        >
          Lanjutkan
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import { nextTick, onMounted, computed } from 'vue'
import { title } from '@/core/page'
import { selectedBranch } from '@/core/subDivisions'
import {
  cart, getCart, clearCart,
  removeFromCart, loading
} from '@/core/cart'
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty.vue'

export default {
  components: { AppBar, Empty, Loading },
  setup () {
    const subTotal = computed(() => {
      return parseInt(cart.value.ap_nombayar) - parseInt(cart.value.nominal_voucher)
    })

    const total = computed(() => {
      return parseInt(subTotal.value)
    })

    onMounted(async () => {
      await getCart()
      nextTick(() => {
        title.value = 'Keranjang Belanja'
      })
    })

    return {
      selectedBranch,
      cart,
      clearCart,
      removeFromCart,
      subTotal,
      total,
      loading
    }
  }
}
</script>

<style lang="scss" scoped>
.address-box {
  position: relative;
  padding: 13px 8px;
  border-radius: 12px;
  padding: 8px;

  .icon-wrapper {
    width: 34px;
    height: 34px;

    img {
      width: 18px;
      height: auto;
    }
  }

  &-info {
    &-name {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    &-address {
      font-size: 12px;
      line-height: 14px;
    }
  }

  // .ri {
  //   position: absolute;
  //   right: .75rem;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   color: rgb(153, 153, 153);
  // }
}

.recomended-list {
  padding: 8px;
  border-radius: 12px;
  gap: 4px;

  .top-recomended {
    &-label {
      font-size: 12px;
      line-height: 14px;
    }

    &-name {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .recomended-item {
    box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
    padding: 8px 0;

    &-name {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 2px;
    }

    &-price {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .recomended-item:last-child {
    box-shadow: unset;
  }
}

.list-service {
  @media (max-width: 520px) {
    padding-bottom: 6rem;
  }

  .service {
    box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;

    &-icon {
      width: 42px;
      height: 42px;
    }

    &-group {
      padding: 12px 0;
    }

    &-title {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 2px;
    }

    &-price {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .service:last-child {
    box-shadow: unset;
  }
}

.bottom-bar {
  .voucher {
    padding: 11px;
    border-radius: 12px;

    &-icon {
      width: 20px;
      height: 20px;
    }

    &-label {
      font-size: 12px;
      line-height: 22px;
    }

    &-use {
      font-size: 10px;
      line-height: 22px;
    }
  }

  .pay {
    &-label {
      font-size: 12px;
      line-height: 14px;
    }

    &-total {
      font-size: 14px;
      line-height: 24px;
    }

    .btn-pay {
      width: 169px !important;
    }
  }
}

.btn-add {
  padding: 8px;
}

.info {
  h3 {
    font-size: 1.15rem;
  }

  p {
    margin: 0;
  }
}
</style>
